<template>
	<div class="container">
		<div class="is-flex is-align-items-center is-justify-content-center mb-3">
			<figure class="image is-128x128">
				<img src="@/assets/logo.svg">
			</figure>
		</div>
		<div class="columns is-centered">
			<div class="column is-two-fifths">
				<div class="card">
					<header class="card-header">
						<p class="card-header-title">
							<span class="icon"><i class="mdi mdi-lock default"></i></span>
							<span>{{ $t('Sign up') }}</span>
						</p>
					</header>
					<div class="card-content">
						<form @submit.prevent="signUp">
							<b-field :label="$t('First name')" :message="errors.firstName" :type="{ 'is-danger': errors.firstName }" label-position="on-border">
								<b-input v-model="firstName" type="text"></b-input>
							</b-field>
							<b-field :label="$t('Last name')" :message="errors.lastName" :type="{ 'is-danger': errors.lastName }" label-position="on-border">
								<b-input v-model="lastName" type="text"></b-input>
							</b-field>
							<b-field :label="$t('Username')" :message="errors.account" :type="{ 'is-danger': errors.account }" label-position="on-border">
								<b-input v-model="account" type="text"></b-input>
							</b-field>
							<b-field :label="$t('E-mail')" :message="errors.email" :type="{ 'is-danger': errors.email }" label-position="on-border">
								<b-input v-model="email" type="email"></b-input>
							</b-field>
							<b-field :label="$t('Password')" :message="errors.password" :type="{ 'is-danger': errors.password }" label-position="on-border">
								<b-input v-model="password" type="password"></b-input>
							</b-field>
							<b-field :label="$t('Repeat password')" :message="errors.repeatPassword" :type="{ 'is-danger': errors.repeatPassword }" label-position="on-border">
								<b-input v-model="repeatPassword" type="password"></b-input>
							</b-field>
							<div class="field">
								<button :class="{ 'is-loading': isSubmitting }" class="button is-link" type="submit"> {{ $t('Sign up') }}</button>
							</div>
							<hr>
							<div class="has-text-centered is-size-7">
								<router-link to="/recover-password">{{ $t('Recover password') }}</router-link> &middot;
								<router-link to="/sign-in">{{ $t('Sign in') }}</router-link>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {encryptData} from "@/utils";

export default {
	name: "SignUp",
	mounted() {
		document.title = this.$t('Sign up') + ' - GIS ANCPI'
		this.$scrollToTop()
	},
	watch: {
		'$i18n.locale'() {
			document.title = this.$t('Sign up') + ' - GIS ANCPI'
		}
	},
	data() {
		return {
			errors: {},
			account: '',
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			repeatPassword: '',
			isSubmitting: false
		}
	},
	methods: {
		isValid() {
			this.errors = {}
			if (!this.account) {
				this.errors.account = this.$t('This field is mandatory!')
			}
			if (!this.firstName) {
				this.errors.firstName = this.$t('This field is mandatory!')
			}
			if (!this.lastName) {
				this.errors.lastName = this.$t('This field is mandatory!')
			}
			if (!this.email) {
				this.errors.email = this.$t('This field is mandatory!')
			}
			if (!this.password) {
				this.errors.password = this.$t('This field is mandatory!')
			} else if (this.password !== this.repeatPassword) {
				this.errors.password = this.$t('Passwords miss match!')
				this.errors.repeatPassword = this.$t('Passwords miss match!')
			}
			return !Object.keys(this.errors).length
		},
		signUp() {
			if (!this.isValid()) {
				this.$buefy.toast.open({
					duration: 3000,
					message: this.$t('Check the errors in page!'),
					type: 'is-danger',
					position: 'is-bottom'
				})
				return false
			}
			this.isSubmitting = true
			this.$store.dispatch('signUp', {
				account: this.account,
				firstName: this.firstName,
				lastName: this.lastName,
				email: this.email,
				password: encryptData(this.password),
				confirmationPassword: encryptData(this.repeatPassword)
			}).then(response => {
				console.log(response);
				this.isSubmitting = false
				this.$buefy.toast.open({
					duration: 3000,
					message: this.$t('Your account has been created!'),
					type: 'is-success',
					position: 'is-bottom'
				})
				this.$router.push('/')
			}).catch(error => {
				this.isSubmitting = false
				this.$buefy.toast.open({
					duration: 3000,
					message: this.$t(error.response ? error.response.data.message : error),
					type: 'is-danger',
					position: 'is-bottom'
				})
			})
		}
	}
}
</script>

<style scoped>

</style>
